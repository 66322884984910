input,
textarea {
    background: transparent;

    /* Placeholder color */
    &::placeholder {
        @apply text-hint;
    }

    &::-moz-placeholder {
        @apply text-hint;
    }

    &::-webkit-input-placeholder {
        @apply text-hint;
    }

    &:-ms-input-placeholder {
        @apply text-hint;
    }

    &:-webkit-autofill {
        -webkit-transition: 'background-color 9999s ease-out';
        -webkit-transition-delay: 9999s;
    }

    &:-webkit-autofill:hover {
        -webkit-transition: 'background-color 9999s ease-out';
        -webkit-transition-delay: 9999s;
    }

    &:-webkit-autofill:focus {
        -webkit-transition: 'background-color 9999s ease-out';
        -webkit-transition-delay: 9999s;
    }

    &:-webkit-autofill:active {
        -webkit-transition: 'background-color 9999s ease-out';
        -webkit-transition-delay: 9999s;
    }
}

.apexcharts-bar-area:hover {
    fill: #6b3af8 !important;
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.72) !important;
}

.border-c-nb {
    border-color: #131626;
}

.wp-box-shadow {
    box-shadow: 1px 1px 0 rgba(19, 22, 38, 0.35);
}

.wp-btn-30 {
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
}

.border-radius-5 {
    border-radius: 5px !important;
}
